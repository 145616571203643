var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("transition", { attrs: { "name": "cookie-slide" } }, [_vm.openedBar ? _c("section", { staticClass: "cookie-bar", attrs: { "aria-label": _vm.$t("section") } }, [!_vm.showDecline ? _c("button", { staticClass: "cookie-close", attrs: { "type": "button", "aria-label": _vm.$t("close") }, on: { "click": function($event) {
    $event.preventDefault();
    return _vm.closePopup(false);
  } } }, [_c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(_vm.$t("close")))])]) : _vm._e(), _c("div", { staticClass: "cookie-text" }, [_vm._t("cookie-text", function() {
    return [_c("p", { staticClass: "sf-text-cb" }, [_vm._v(_vm._s(_vm.$t("text")))])];
  })], 2), _c("div", { staticClass: "cookie-buttons" }, [_c("button", { staticClass: "sf-btn-cb", on: { "click": function($event) {
    $event.preventDefault();
    return _vm.closePopup(true);
  } } }, [_vm._t("cookie-btn", function() {
    return [_vm._v(_vm._s(_vm.$t("acceptButtonText")))];
  })], 2), _vm.showDecline ? _c("button", { staticClass: "sf-btn-cb sf-btn-cb--link cookie-button-declined", on: { "click": function($event) {
    $event.preventDefault();
    return _vm.closePopup(false);
  } } }, [_vm._t("cookie-btn-decline", function() {
    return [_vm._v(_vm._s(_vm.$t("declineButtonText")))];
  })], 2) : _vm._e(), _c("a", { staticClass: "sf-link-cb", attrs: { "href": _vm.detailsUrl, "target": "_blank" } }, [_vm._t("details-link", function() {
    return [_c("span", [_vm._v(_vm._s(_vm.$t("details")))])];
  })], 2)])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
